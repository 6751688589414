import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiError, setUiMessage } from './ui';

export const initialState = {
	features: {},
};

// A slice
const systemFeaturesSlice = createSlice({
	name: 'systemFeatures',
	initialState,
	reducers: {
		setFeatures: (state, action) => {
			state.features = action.payload;
		},
	},
});

// Actions generated from the slice
const { setFeatures } = systemFeaturesSlice.actions;

// export user selector to get the slice in any component
export const systemFeaturesSelector = (state) => state.features;

// export The reducer
const systemFeaturesReducers = systemFeaturesSlice.reducer;

export default systemFeaturesReducers;

// Actions
export const fetchSystemFeatureFlags =
	(page = 1) =>
		async (dispatch) => {
			try {
				const response = await fetchWrapper.get(`/features/flags/`, true);
				dispatch(setFeatures(response.data))
			} catch (error) {
			}
		};