import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './users';
import languageReducer from './languages';
import uiReducer from './ui';
import formTemplatesReducer from './formTemplates';
import fiscalReducer from './fiscalyear';
import paperReducer from './paper';
import wardReducer from './ward';
import templateCategoryReducer from './template';
import documentReducer from './documents';
import ito_usersReducer from './ito_users';
import nagarpalikaReducer from './nagarpalika';
import sifarishTypesReducer from './sifarishTypes';
import systemFeaturesReducer from './systemFeatures';
import applicantsReducer from './applicants';
import designTemplateReducer from './designTemplates';
import dartaReducer from './darta';
import chalaniReducer from './chalani';

const rootReducer = combineReducers({
	users: userReducer,
	language: languageReducer,
	ui: uiReducer,
	formTemplates: formTemplatesReducer,
	fiscal: fiscalReducer,
	papers: paperReducer,
	wards: wardReducer,
	templateCategories: templateCategoryReducer,
	documents: documentReducer,
	nagarpalika: nagarpalikaReducer,
	sifarishTypes: sifarishTypesReducer,
	applicants: applicantsReducer,
	designTemplates: designTemplateReducer,
	darta: dartaReducer,
	chalani: chalaniReducer,
	ito_users: ito_usersReducer,
	system_features: systemFeaturesReducer,
});

export default rootReducer;
