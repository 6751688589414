import NepaliDate from "nepali-date-converter";
import { nepaliNumbers } from "../_datas";
import moment from "moment";
import { fetchWrapper } from "./fetchWrapper";
var adbs = require("ad-bs-converter");

export function englishNumberConverter(numString) {
  let englishNumber = "";
  for (let i = 0; i < numString.length; i++) {
    let num = numString[i];
    let englishNum = nepaliNumbers.indexOf(num);
    if (englishNum < 0) {
      englishNumber += num;
    } else {
      englishNumber += englishNum;
    }
  }
  return englishNumber;
}

export function nepaliNumberConverter(numString) {
  numString = `${numString}`;
  let nepaliNumber = "";
  for (let i = 0; i < numString?.length; i++) {
    if (nepaliNumbers.includes(numString[i])) {
      nepaliNumber += numString[i];
    } else if (nepaliNumbers[numString[i]]) {
      nepaliNumber += nepaliNumbers[numString[i]];
    } else {
      nepaliNumber += numString[i];
    }
  }
  return nepaliNumber;
}

export function getTodayMonth() {
  let todayMonth = new NepaliDate().format("M");
  todayMonth = String(todayMonth);
  if (todayMonth.length < 2) {
    return "0" + todayMonth;
  } else {
    return todayMonth;
  }
}

export function getTodayDay() {
  let todayDay = new NepaliDate().format("D");
  todayDay = String(todayDay);
  if (todayDay.length < 2) {
    return "0" + todayDay;
  } else {
    return todayDay;
  }
}

export function getTodayDate() {
  let today = new NepaliDate().format("YYYY/MM/DD");
  console.log(today, "today");
  today = String(today);
  return today;
}

export const nepaliNumber = (number) => {
  const strNum = String(number);
  let strNepNum = "";
  for (let i = 0; i < strNum.length; i++) {
    strNepNum +=
      nepaliNumbers[parseInt(strNum.substr(i, 1))] ||
      (nepaliNumbers.includes(strNum.substr(i, 1)) && strNum.substr(i, 1)) ||
      "";
  }
  return strNepNum;
};

const generateUneditable = (data) => `<span class="nonEditable">${data}</span>`;

const generateNameRelationBox = (data, config) => {
  const noOfRow = Math.ceil(data.length / 5);
  const html = [];
  for (let index = 0; index < noOfRow; index++) {
    html.push(
      `<tr>${data
        .map(
          (data, idx) =>
            index === Math.floor(idx / 5) &&
            `<td><div class="text-container"><div class="photobox"></div><div>${
              data[config.name]
            }</div><p>(${data[config.relation]})</p></div></td>`
        )
        .filter((data) => data)
        .join("")}</tr>`
    );
  }
  return html.join("");
};

export const generatePreview = (paper, formData, user) => {
  let nagarpalikaName_en = user.profile?.nagarpalika_info?.name_en;
  let nagarpalikaName_np = user.profile?.nagarpalika_info?.name_np;
  let nagarpalikaAddress_en = user.profile?.nagarpalika_info?.address_en;
  let nagarpalikaAddress_np = user.profile?.nagarpalika_info?.address_np;
  let ward_name_en = user.profile?.ward_info?.name_en;
  let ward_name_np = user.profile?.ward_info?.name_np;

  let tempPreviewData = paper.preview;
  let metaData = paper.meta;
  let curlyBraceGraberRegex = /\{\{(.*?)\}\}/g; // {{text1}}
  tempPreviewData = tempPreviewData.replace(curlyBraceGraberRegex, (value) => {
    let key = value.replace(/[^0-9a-zA-Z_-]+/g, ""); // text1
    if (key.startsWith("fullname")) {
      return generateUneditable(
        formData[key] &&
          `${formData[key]?.firstName} ${formData[key]?.middleName} ${formData[key]?.lastName}`
      );
    } else if (key.includes("_logo")) {
      return formData[key];
    } else if (key.startsWith("date")) {
      if (typeof formData[key] === "object") {
        let joinedDate = Object.values(formData[key]).join("/ ");
        return generateUneditable(joinedDate);
      } else {
        return generateUneditable(formData[key]);
      }
    } else if (key.startsWith("dob")) {
      if (typeof formData[key] === "object") {
        let joinedDate = Object.values(formData[key]).join("/ ");
        return generateUneditable(joinedDate);
      } else {
        return generateUneditable(formData[key]);
      }
    } else if (key === "nagarpalika_name_np") {
      return generateUneditable(nagarpalikaName_np);
    } else if (key === "nagarpalika_name_en") {
      return generateUneditable(nagarpalikaName_en);
    } else if (key === "nagarpalika_address_np") {
      return generateUneditable(nagarpalikaAddress_np);
    } else if (key === "nagarpalika_address_en") {
      return generateUneditable(nagarpalikaAddress_en);
    } else if (key === "ward_name_np") {
      return generateUneditable(ward_name_np);
    } else if (key === "ward_name_en") {
      return generateUneditable(ward_name_en);
    } else if (metaData && metaData[key]) {
      return generateUneditable(metaData[key]);
    }

    // is handled in the template itself
    // else if (key.startsWith('post')) {
    // 	return '';
    // } else if (key.startsWith('admin_name')) {
    // 	return '';
    // }
    else if (key.startsWith("post")) {
      let post;
      try {
        // ward_admin => admin-name_admin-post ,, ram_adhakchya
        post = formData["ward_admin"]?.split("_")[1];
        if (post) {
          return generateUneditable(post);
        } else {
          post = "";
        }
      } catch (err) {
        post = "";
      }
      return generateUneditable(post);
    } else if (key.startsWith("admin_name")) {
      let admin_name;
      try {
        admin_name = formData["ward_admin"]?.split("_")[0];
        if (admin_name) {
          return admin_name;
        } else {
          admin_name = "";
        }
      } catch (err) {
        admin_name = "";
      }
      return generateUneditable(admin_name);
    } else if (key === "relation") {
      let sex = formData["select2"];
      if (sex === "male") {
        return generateUneditable("छोरा");
      } else if (sex === "female") {
        return generateUneditable("छोरी");
      } else {
        return generateUneditable("पुत्र");
      }
    } else if (key === "respect") {
      let sex = formData["select4"];
      if (sex === "male") {
        return generateUneditable("श्रीमान");
      } else if (sex === "female") {
        return generateUneditable("श्री");
      } else {
        return generateUneditable("श्री");
      }
    } else if (key === "age") {
      // 1997-01-22
      let age = moment().diff(
        Object.values(formData["date_of_birth_AD"]).join("-"),
        "years"
      );
      return generateUneditable(nepaliNumber(age));
    } else if (key.startsWith("table")) {
      if (formData[key]?.length > 0) {
        let tableData =
          '<div class="content-container"><table class="table table-bordered" id="table_resize" border="0" cellpadding = "0" cellspacing="0"><tr>';
        const headings = Object.keys(formData[key][0]);
        // const costumeKey = "table";
        // "table__r__1,2"
        const costumeKey = key;

        // eslint-disable-next-line no-unused-vars
        const [_, relationFields] = costumeKey.split("__r__");
        let name, relation;

        if (relationFields) {
          [name, relation] = relationFields.split("_");
        }
        const sortedHeadings = headings.sort();
        const headingData = sortedHeadings
          .map((heading, headingIndex) =>
            headingIndex === 0
              ? `<th style="width:60px;">${heading
                  .split("___")[1]
                  .split("####")
                  .join(".")
                  .split("&&&&")
                  .join("'")}</th>`
              : `<th>${heading
                  .split("___")[1]
                  .split("####")
                  .join(".")
                  .split("&&&&")
                  .join("'")}</th>`
          )
          .join("");
        tableData += headingData;
        const rows = formData[key].map(
          (rowData) =>
            `<tr>${headings
              .map((heading, headingIndex) =>
                headingIndex === 0
                  ? `<td style="max-width:25px;width:25px;">${rowData[heading]}</td>`
                  : `<td>${rowData[heading]}</td>`
              )
              .join("")}</tr>`
        );
        if (relationFields) {
          tableData += `</tr>${rows.join(
            ""
          )}</table></div><div class="tablephotocontainer"><table class="table" id="table_resize" border="0" cellpadding = "0" cellspacing="0">${generateNameRelationBox(
            formData[key],
            {
              name: sortedHeadings[name],
              relation: sortedHeadings[relation],
            }
          )}</table></div>`;
        } else tableData += `</tr>${rows.join("")}</table></div>`;
        return generateUneditable(tableData);
      }
      return null;
    } else {
      return generateUneditable(formData[key]);
    }
  });

  return tempPreviewData;
};

export function getDifference(a, b) {
  var i = 0;
  var j = 0;
  var result = "";
  let position;
  while (j < b.length) {
    if (a[i] !== b[j] || i === a.length) {
      result = b[j];
      position = j;
      break;
    } else i++;
    j++;
  }
  return { result, position };
}

export function normalizeHtml(str) {
  return (
    str &&
    str
      .replace(/&nbsp;|\u202F|\u00A0/g, " ")
      .replace(/&lt;/g, "ÿ") // < and <</p> causes a problem because 1st character is equal
      //and it always takes second one so this little hack prevents using  < at any cost
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
  );
}

function createRange(node, chars, range) {
  if (!range) {
    range = document.createRange();
    range.selectNode(node);
    range.setStart(node, 0);
  }

  if (chars.count === 0) {
    range.setEnd(node, chars.count);
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length;
      } else {
        range.setEnd(node, chars.count);
        chars.count = 0;
      }
    } else {
      for (var lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range);

        if (chars.count === 0) {
          break;
        }
      }
    }
  }

  return range;
}

export function setCurrentCursorPosition(
  chars,
  preview = "preview",
  insertCharacter
) {
  if (chars >= 0) {
    const selection = window.getSelection();

    let range = createRange(document.getElementById(preview).parentNode, {
      count: chars,
    });

    if (range) {
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}

export function getCurrentCursorPosition() {
  const element = document.getElementById("preview");
  var caretOffset = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var sel;
  if (typeof win.getSelection != "undefined") {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type !== "Control") {
    var textRange = sel.createRange();
    var preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint("EndToEnd", textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

export const debounce = (func, delay = 500) => {
  let timeout;
  return function (...args) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const urlPublicChecker = (url, isPublic = false) => {
  if (isPublic) {
    return "/public" + url;
  }
  return url;
};

export const errorMessageChecker = (errors, id) => {
  const keys = id?.split(".");
  let errorMessage = keys.reduce((acc, cur) => {
    if (!acc) {
      return acc;
    }
    let value = acc[cur];
    if (value?.message) {
      return value.message;
    } else {
      return value;
    }
  }, errors);

  return errorMessage;
};

export const formValidationCreator = (validations, label) => {
  let formValidations = {};
  if (validations?.isRequired) {
    formValidations.required = `${label ?? ""} अनिवार्य छ!`;
  }
  return formValidations;
};

export const fileUploader = async (files, uploadMode) => {
  try {
    let formData = new FormData();
    // let type = null;
    let file;
    if (uploadMode === "single") {
      file = files;
    } else {
      file = files[0];
    }
    // console.log('Checking file extension', fileExt);

    formData.append("title", file.name);
    formData.append("file", file);

    const response = await fetchWrapper.post("/add-documents/", formData, true);
    return response;
  } catch (err) {}
};

export const nepalifyDate = (date) => {
  let convertedDate = "";
  try {
    let { ne } = adbs.ad2bs(date.replaceAll("-", "/"));

    convertedDate = `${ne.year}-${ne.month.padStart(2, "०")}-${ne.day.padStart(
      2,
      "०"
    )}`;
  } catch (err) {
    convertedDate = nepaliNumberConverter(date);
  }
  return convertedDate;
};
