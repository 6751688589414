import { useEffect, useState } from "react";
import { VscLoading } from "react-icons/vsc";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { loginUserWIthSSO } from '../../_redux/slices/users';
import { useHistory } from "react-router-dom";
import { USER_PROFILE_TOKEN_NAME } from "../../constant";
import { css } from "@emotion/react";

export default function SSORedirect() {
    const history = useHistory();

    const token = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        const getTokens = async () => {
            const response = await fetchWrapper.get(`/oauth/login/?token=${token}`);
            loginUserWIthSSO(response.data, history);
        };
        try {
            if (token) {
                localStorage.setItem(USER_PROFILE_TOKEN_NAME, token);
                getTokens();
            }
        } catch (error) {
        }
    }, [token, history, loginUserWIthSSO]);

    return (
        <div css={css`
            display: flex;
            min-height: 100vh;
            justify-content: center;
            padding-top: 150px;
        `}>
            <VscLoading className="spin" fontSize={54} />
        </div>
    )
}