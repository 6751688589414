import { lazy, Suspense, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

//
import { AuthRoute, PrivateRoute } from './auth-route';

//
import Layout from './layout/Layout';
import Loader from './_elements/Loader';

//
import { clearUiMessage } from './_redux/slices/ui';
import { getUserData } from './_redux/slices/users';
import { fetchNagarpalika } from './_redux/slices/nagarpalika';
import { fetchActiveFiscalYear } from './_redux/slices/fiscalyear';
import { fetchSystemFeatureFlags } from './_redux/slices/systemFeatures';


//
import './sass/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import './components/Form/index.css';
import { Helmet } from 'react-helmet';
import Features from './pages/Settings/Features/Features';
import SSORedirect from './pages/SSORedirect/SSORedirect';

//
const Darta = lazy(() => import('./pages/Darta'));
const NewDarta = lazy(() => import('./pages/Darta/NewDarta'));
const Chalani = lazy(() => import('./pages/Chalani'));
const NewChalani = lazy(() => import('./pages/Chalani/NewChalani'));

// auth
const Login = lazy(() => import('./pages/auth/Login'));
const SignUp = lazy(() => import('./pages/auth/SignUp'));
const ResetPassword = lazy(() => import('./pages/auth/ResetPassword'));
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));

const Faq = lazy(() => import('./pages/Faq'));
const Report = lazy(() => import('./pages/Report'));
const Support = lazy(() => import('./pages/Support'));
const Dashboard = lazy(() => import('./pages/OverviewDashboard'));

// Settings
const Ward = lazy(() => import('./pages/Settings/Ward/Ward'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const AddWard = lazy(() => import('./pages/Settings/Ward/AddWard'));
const FiscalYear = lazy(() => import('./pages/Settings/FiscalYear'));
const Profile = lazy(() => import('./pages/Settings/Profile/Profile'));
const AllUsers = lazy(() => import('./pages/Settings/Users/AllUsers'));
const WardUser = lazy(() => import('./pages/Settings/Users/WardUser'));

const NagarpalikaDetails = lazy(() => import('./pages/NagarpalikaDetails/NagarpalikaDetails'));
//
const Users = lazy(() => import('./pages/Users/Users'));

/**
 *
 */
function App({ clearUiMessage, fetchNagarpalika, fetchSystemFeatureFlags, getUserData, fetchActiveFiscalYear }) {
	const message = useSelector((state) => state.ui.message);
	const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
	const nagarpalikaInfo = useSelector((state) => state.nagarpalika?.nagarpalika);

	useEffect(() => {
		fetchNagarpalika();
		fetchSystemFeatureFlags();
	}, [fetchNagarpalika]);

	useEffect(() => {
		if (isLoggedIn) {
			getUserData();
			fetchActiveFiscalYear();
		}
	}, [getUserData, fetchActiveFiscalYear, isLoggedIn]);

	useEffect(() => {
		if (message) {
			if (message.error === false) {
				toast.success(message.message);
				clearUiMessage();
			} else if (message) {
				toast.error(message.message);
				clearUiMessage();
			} else {
				toast.success(message);
			}
		}
	}, [message, clearUiMessage]);

	return (
		<Suspense fallback={<Loader />}>

			<Helmet>
				<title>
					{
						`${nagarpalikaInfo?.name_np ? `${nagarpalikaInfo?.name_np} | ` : ''} दर्ता/चलानी प्रणाली`
					}
				</title>
			</Helmet>
			<Router>
				<Switch>
					<AuthRoute exact path="/login" component={Login} />

					<Route exact path="/signup" component={SignUp} />
					<Route exact path="/sso-redirect" component={SSORedirect} />

					<Route exact path="/forgot-password" component={ForgotPassword} />
					<Route exact path="/password/reset" component={ResetPassword} />

					<Route path="/" render={({ match }) => <RootRoutes basePath={match.path} />} />
				</Switch>
				<ToastContainer
					position="top-right"
					autoClose={3000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Router>
		</Suspense> 
	);
}

export default connect(null, {
	clearUiMessage,
	getUserData,
	fetchNagarpalika,
	fetchSystemFeatureFlags,
	fetchActiveFiscalYear,
})(App);

const RootRoutes = ({ basePath }) => (
	<Layout>
		<PrivateRoute
			exact
			path="/darta/:pageNum"
			component={Darta}
			accessGrantedRoles={[
				'superuser',
				'ito_admin',
				'ward_admin',
				'ward_user',
				'ward_paper_issuer',
				'mayor',
				'upa_mayor',
			]}
		/>
		<PrivateRoute
			exact
			path="/new/darta"
			component={NewDarta}
			accessGrantedRoles={[
				'superuser',
				'ito_admin',
				'ward_admin',
				'ward_user',
				'ward_paper_issuer',
				'mayor',
				'upa_mayor',
			]}
		/>
		<PrivateRoute
			exact
			path="/edit/darta/:dartaId"
			component={NewDarta}
			accessGrantedRoles={[
				'superuser',
				'ito_admin',
				'ward_admin',
				'ward_user',
				'ward_paper_issuer',
				'mayor',
				'upa_mayor',
			]}
		/>
		<PrivateRoute
			exact
			path="/chalani/:pageNum"
			component={Chalani}
			accessGrantedRoles={[
				'superuser',
				'ito_admin',
				'ward_admin',
				'ward_user',
				'ward_paper_issuer',
				'mayor',
				'upa_mayor',
			]}
		/>
		<PrivateRoute
			exact
			path="/new/chalani"
			component={NewChalani}
			accessGrantedRoles={[
				'superuser',
				'ito_admin',
				'ward_admin',
				'ward_user',
				'ward_paper_issuer',
				'mayor',
				'upa_mayor',
			]}
		/>
		<PrivateRoute
			exact
			path="/edit/chalani/:chalaniId"
			component={NewChalani}
			accessGrantedRoles={[
				'superuser',
				'ito_admin',
				'ward_admin',
				'ward_user',
				'ward_paper_issuer',
				'mayor',
				'upa_mayor',
			]}
		/>
		<PrivateRoute
			exact
			path="/"
			component={Dashboard}
			accessGrantedRoles={[
				'superuser',
				'ito_admin',
				'ward_admin',
				'ward_user',
				'ward_paper_issuer',
				'mayor',
				'upa_mayor',
			]}
		/>
		<PrivateRoute
			exact
			path="/reports"
			component={Report}
			accessGrantedRoles={[
				'superuser',
				'ito_admin',
				'ward_admin',
				'ward_user',
				'ward_paper_issuer',
				'mayor',
				'upa_mayor',
			]}
		/>
		<PrivateRoute
			exact
			path="/settings"
			component={Settings}
			accessGrantedRoles={['superuser', 'ito_admin', 'ward_admin']}
		/>
		<PrivateRoute
			exact
			path="/settings/fiscalyear"
			component={FiscalYear}
			accessGrantedRoles={['superuser', 'ito_admin']}
		/>
		<PrivateRoute exact path="/settings/ward" component={Ward} accessGrantedRoles={['superuser', 'ito_admin']} />
		<PrivateRoute
			exact
			path="/settings/allusers/:pageNum"
			component={AllUsers}
			accessGrantedRoles={['superuser', 'ito_admin', 'ward_admin', 'ward_user', 'ward_paper_issuer']}
		/>
		<PrivateRoute
			exact
			path="/settings/warduser/:id"
			component={WardUser}
			accessGrantedRoles={['superuser', 'ito_admin', 'ward_admin', 'ward_user', 'ward_paper_issuer']}
		/>
		<PrivateRoute
			exact
			path="/settings/profile"
			component={Profile}
			accessGrantedRoles={[
				'superuser',
				'ito_admin',
				'ward_admin',
				'ward_user',
				'ward_paper_issuer',
				'mayor',
				'upa_mayor',
			]}
		/>
		<PrivateRoute
			exact
			path="/settings/features"
			component={Features}
			accessGrantedRoles={[
				'superuser',
			]}
		/>

		<PrivateRoute
			exact
			path="/faq"
			component={Faq}
			accessGrantedRoles={['superuser', 'ito_admin', 'ward_admin', 'ward_user', 'ward_paper_issuer']}
		/>
		<PrivateRoute
			exact
			path="/support"
			component={Support}
			accessGrantedRoles={['superuser', 'ito_admin', 'ward_admin', 'ward_user', 'ward_paper_issuer']}
		/>
		<PrivateRoute exact path="/ward/create/" component={AddWard} accessGrantedRoles={['superuser', 'ito_admin']} />
		<PrivateRoute exact path="/ward/update/:id" component={AddWard} accessGrantedRoles={['superuser', 'ito_admin']} />
		<PrivateRoute
			exact
			path="/nagarpalika/:pageNum"
			component={NagarpalikaDetails}
			accessGrantedRoles={['superuser', 'ito_admin']}
		/>
		<PrivateRoute
			exact
			path="/users"
			component={Users}
			accessGrantedRoles={['superuser', 'ito_admin', 'ward_admin', 'ward_user', 'ward_paper_issuer']}
		/>
	</Layout>
);
