import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileData } from "../_redux/slices/users";

const Layout = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.users);

  const [showMenu, setShowMenu] = useState(true);
  //
  const getSession = async () => {
    localStorage.removeItem('showLoginPage');
    dispatch(getUserProfileData());
  };


  useEffect(() => {
    if (user.isLoggedIn) {
      getSession();
    }
    
  }, []);

  return (
    <div className="main-wrapper">
      <div className="d-flex">
        <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} />
        <div className="right-content">
          <Header />
          <div className="content-wrapper">
            <div className="main-content">{props.children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
