import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { logoutUser } from './users';

export const initialState = {
	loading: false,
	hasErrors: false,
	nagarpalika: null,
};

// A slice
const nagarpalikaSlice = createSlice({
	name: 'nagarpalika',
	initialState,
	reducers: {
		getNagarpalika: (state, action) => {
			state.nagarpalika = action.payload;
		},
	},
});

// Actions generated from the slice
const { getNagarpalika } = nagarpalikaSlice.actions;

// export user selector to get the slice in any component
export const nagarpalikaSelector = (state) => state.nagarpalika;

// export The reducer
const nagarpaikaReducer = nagarpalikaSlice.reducer;

export default nagarpaikaReducer;

// Actions
export const fetchNagarpalika = () => async (dispatch) => {
	try {
		const response = await fetchWrapper.get('/nagarpalika/', true);
		if (response.status === 401) {
			dispatch(logoutUser());
			return null;
		}
		const nagarpalika = response.data.results[0];
		dispatch(getNagarpalika(nagarpalika));
	} catch (error) {
		// console.log(error);
	}
};
