import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiMessage } from './ui';

export const initialState = {
	loading: false,
	hasErrors: false,
	applicants: [],
	count: 0,
};

// A slice
const applicantsSlice = createSlice({
	name: 'applicants',
	initialState,
	reducers: {
		getApplicants: (state, action) => {
			state.applicants = action.payload.data.results;
			state.count = action.payload.count;
		},

		addApplicant: (state, action) => {
			state.applicants = [action.payload, ...state.applicants];
			state.count = state.count + 1;
		},

		updateApplicant: (state, action) => {
			const applicants = [...state.applicants];

			const editedIndex = applicants.findIndex((applicant) => applicant.id === action.payload.id);

			if (editedIndex !== -1) {
				applicants[editedIndex] = action.payload;
			}
			state.applicants = applicants;
		},
		removeApplicant: (state, action) => {
			const applicants = [...state.applicants];

			const editedIndex = applicants.findIndex((applicant) => applicant.id === action.payload);
			applicants.splice(editedIndex, 1);
			state.applicants = applicants;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
	},
});

// Actions generated from the slice
const { getApplicants, addApplicant, updateApplicant, removeApplicant, setLoading } = applicantsSlice.actions;

// export user selector to get the slice in any component
export const applicantsSelector = (state) => state.applicants;

// export The reducer
const applicantsReducer = applicantsSlice.reducer;

export default applicantsReducer;

// Actions
export const fetchApplicants = () => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const applicants = await fetchWrapper.get('/mayor/');
		dispatch(getApplicants(applicants));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const createApplicant = (data, hideApplicantForm) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const applicant = await fetchWrapper.post('/applicants/register/', data);
		dispatch(addApplicant(applicant.data));
		hideApplicantForm();
		// console.log("applicant.message", applicant.message);
		dispatch(setUiMessage(applicant.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const editApplicant = (data, setShowAdd, setDocumentDetail) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const applicant = await fetchWrapper.patch(`/mayor/${data.id}/`, data);

		dispatch(updateApplicant(applicant.data));
		setShowAdd((prev) => !prev);
		setDocumentDetail(null);
		dispatch(setUiMessage(applicant.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const deleteApplicant = (id) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const applicant = await fetchWrapper.delete(`/mayor/${id}/`);
		dispatch(removeApplicant(id));
		dispatch(setUiMessage(applicant.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};
