import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";

const initialState = {
  isLoading: false,
  chalaniList: [],
  chalani: null,
  count: 0,
  organization: null,
};

const chalaniSlice = createSlice({
  name: "chalani",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setChalaniList: (state, action) => {
      state.chalaniList = action.payload.results;
      state.count = action.payload.count;
    },
    setChalani: (state, action) => {
      state.chalani = action.payload;
    },
    setNewChalani: (state, action) => {
      state.chalaniList = [action.payload, ...state.chalaniList];
      state.count = ++state.count;
    },
    setOrganization: (state, action) => {
      state.organization = action.payload;
    },
  },
});

const { setChalaniList, setOrganization, setIsLoading } = chalaniSlice.actions;

export default chalaniSlice.reducer;

export const getChalaniList =
  (page, organization, filters = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const queryParams = new URLSearchParams();
      const { chalaniNo, receiving_department, fiscalYear } = filters;

      if (!fiscalYear) {
        return;
      }
      queryParams.append("fiscal_year", fiscalYear);

      if (page) queryParams.append("page", page);

      if (organization?.value)
        queryParams.append("organization", organization?.value);
      if (chalaniNo) queryParams.append("serial_number", chalaniNo);
      if (receiving_department)
        queryParams.append("receiving_department", receiving_department);

      const response = await fetchWrapper.get(`/chalani/?${queryParams}`);
      dispatch(setChalaniList(response));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setIsLoading(false));
    }
  };

export const handleSetOrganization = (organization) => async (dispatch) => {
  dispatch(setOrganization(organization));
};
