/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Button, Modal } from 'react-bootstrap'
import InputField from '../../../components/InputField';
import { InputFieldStyleOverwrite } from '../Ward/AddWard';
import { useSelector } from 'react-redux';
import Switch from "react-switch";
import { useEffect, useState } from "react";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { VscLoading } from "react-icons/vsc";
import Swal from "sweetalert2";

function FeatureModal({ show, handleClose, data, onSuccess }) {
  const language = useSelector(state => state.language.language);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [entity, setEntity] = useState('FE');
  const [checked, setChecked] = useState(true);

  console.log(data, 'data')
  useEffect(() => {
    if (show && data) {
      setName(data.name);
      setDescription(data.description);
      setEntity(data.entity);
      setChecked(data.enabled);
    }
  }, [show, data]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const body = {
        name, description, entity, enabled: checked
      }
      if (data) {
        const response = await fetchWrapper.patch(`/features/${data.id}/`, body);
      }
      else {
        const response = await fetchWrapper.post('/features/', body);
      }
      onClose();
      onSuccess();
    }
    catch (err) { console.log(err) }
    finally {
      setLoading(false);
    }
  }

  const handleDescriptionChange = (value) => setDescription(value);
  const handleNameChange = (e) => setName(e.target.value);
  const handleEntityChange = (value) => setEntity(value);
  const handleEnabledChange = (value) => setChecked(value);


  const onClose = () => {
    setChecked(true);
    setEntity('FE');
    setDescription('');
    setName('');
    handleClose();
  }

  const handleDeleteBtnClick = async () => {
    await fetchWrapper.delete(`/features/${data.id}/`);
    onClose();
    onSuccess();
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{data ? 'Edit' : 'Add'} <span className='ml-1'>Feature </span></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* name, deescription, enabled, entity */}
        <label className="font-weight-bold">
          <div>
            Name
            <span className="ml-2 text-small">Eg: SSO_FEATURE</span>
          </div>
          <input
            css={css`
            ${InputFieldStyleOverwrite};
            width: 100% !important;`}
            className={`textbox_req`}
            value={name}
            onChange={handleNameChange}
          />
        </label>

        <div>
          <label className="font-weight-bold">
            <div>
              Description
            </div>
            <InputField
              css={css`
            ${InputFieldStyleOverwrite};
            width: 100% !important;`}
              type="text"
              className={`textbox_req`}
              language={language}
              classNameName={`input`}
              elementId={'description'}
              isRequired={true}
              value={description}
              getInputData={handleDescriptionChange}
            />
          </label>
        </div>

        <div>
          <div className="font-weight-bold">
            Entity
          </div>
          <div className="d-flex gap-2 align-items-center" onChange={(e) => handleEntityChange(e.target.value)}>
            <label className="d-flex align-items-center  mr-3">
              <input type="radio" value="FE" name="entity" className="mr-1" defaultChecked={entity === "FE"} /> Frontend
            </label>

            <label className="d-flex align-items-center  mr-3">
              <input type="radio" value="BE" name="entity" className="mr-1" defaultChecked={entity === "BE"} /> Backend
            </label>
          </div>
        </div>

        <div>
          <label>
            <div className="font-weight-bold">
              Enabled
            </div>
            <div className="d-flex gap-2 align-items-center">
              <Switch onChange={handleEnabledChange} checked={checked} />
            </div>
          </label>
        </div>

        {
          data &&
          <div>
            <Button variant="outline-danger" onClick={() => {
              Swal.fire({
                title: 'के तपाई यो मेटाउन चाहनुहुन्छ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'सुनिश्चित गर्नुहोस्',
                cancelButtonText: 'रद्द गर्नुहोस्',
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleDeleteBtnClick();
                }
              });
            }}>Delete this variable</Button>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" disabled={loading} onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" disabled={loading} onClick={() => {
          if (!data) {

            handleSave()
          }
          else {

            Swal.fire({
              title: 'Are you sure you want to edit this flag?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              cancelButtonText: 'Cancel',
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {

                handleSave()
              }
            });
          }
        }
        }>
          {loading ? <VscLoading className="spin" /> : 'Save'}</Button>
      </Modal.Footer>
    </Modal >
  )
}

export default FeatureModal;
