/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
// eslint-disable-next-line no-unused-vars
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { setUiMessage } from '../../../_redux/slices/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { wardValidationSchema } from '../../../_helpers/schema-validation';
import InputField from '../../../components/InputField';
import { VscLoading } from 'react-icons/vsc';
import { setUiError } from '../../../_redux/slices/formTemplates';

export const InputFieldStyleOverwrite = css`
	border: 1px solid #ccc !important;
	border-radius: 0.25rem;
	width: 164px !important;
	height: calc(1.5em + 0.75rem + 2px);
	color: #333 !important;
	padding: 3px 4px !important;
	background-color: #fff !important;

	:active,:focus {
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
	}
`;

const AddWard = () => {
	const { id } = useParams();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		shouldUnregister: true,

		resolver: yupResolver(wardValidationSchema),
	});
	const dispatch = useDispatch();
	const history = useHistory();

	const language = useSelector((state) => state.language.language);
	const [defaultVal, setDefaultVal] = useState(null);
	const [nagarpalikas, setNagarpalikas] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (id) {
			const getWard = async () => {
				try {
					const res = await fetchWrapper.get(`/ward/${id}/`);
					setDefaultVal(res.data);
				} catch (err) { }
			};
			getWard();
		}
	}, [id]);

	useEffect(() => {
		if (defaultVal) {
			setValue('name_np', defaultVal?.name_np || defaultVal?.name);
			setValue('name_en', defaultVal?.name_en || defaultVal?.name);
			setValue('phone_np', defaultVal?.phone_np || defaultVal?.phone);
			setValue('phone_en', defaultVal?.phone_en);
			setValue('address_np', defaultVal?.address_np || defaultVal?.address);
			setValue('address_en', defaultVal?.address_en || defaultVal?.address);
			setValue('email', defaultVal?.email);
		}
	}, [setValue, defaultVal]);

	useEffect(() => {
		const getNagarpalikas = async () => {
			try {
				let response = await fetchWrapper.get('/nagarpalika/');
				let nagarpalikaResult = response.data.results;
				setNagarpalikas(nagarpalikaResult);
			} catch (err) { }
		};
		getNagarpalikas();
	}, []);

	const onSubmit = async (data) => {
		try {
			setIsLoading(true);

			let body = {
				...data,
			};

			if (body && !id) {
				await fetchWrapper
					.post('/ward/', body)
					.then((res) => {
						dispatch(setUiMessage('नया वडा कार्यालय थपियो '));
						history.push('/settings/allusers/1');
					})
					.catch((err) => {
						Object.keys(err.error).forEach((errorKey) => {
							dispatch(setUiError(err.error[errorKey][0]));
						});
					});
			} else if (body && id) {
				await fetchWrapper
					.patch(`/ward/${id}/`, body)
					.then((res) => {
						dispatch(setUiMessage('वडा कार्यालयको जानकारी परिबर्तन गरियो ।'));
						history.push('/settings/allusers/1');
					})
					.catch((err) => {
						// console.log(err, err.error?.non_field_error);
						err.error?.non_field_error && dispatch(setUiError(err.error?.non_field_error[0]));
					});
			}
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
		}
	};

	return (
		<Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Card
					css={css`
						border-radius: 8px;
						box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
					`}
				>
					<Card.Header className="card-header-custom px-3">
						<h3>वडा कार्यालयको विवरण</h3>
					</Card.Header>
					<Card.Body className="px-3">
						<Row>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">Nagarpalika</Form.Label>
									{nagarpalikas && (
										<select
											{...register('nagarpalika')}
											css={InputFieldStyleOverwrite}
											defaultValue={defaultVal?.nagarpalika}
										>
											{nagarpalikas.map((nagarpalika) => {
												return (
													<option key={nagarpalika.id} value={nagarpalika.id}>
														{nagarpalika.name_np}
													</option>
												);
											})}
										</select>
									)}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">वडा नम्बर</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={language}
										classNameName={`input`}
										elementId={'name_np'}
										placeholder={'वडा नम्बर भर्नुहोस्'}
										maxLength={50}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.name_np ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.name_np.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">वडा नम्बर (अंग्रेजीमा)</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={language}
										classNameName={`input`}
										elementId={'name_en'}
										placeholder={'Enter ward number'}
										maxLength={50}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.name_en ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.name_en.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">ईमेल</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={language}
										classNameName={`input`}
										elementId={'email'}
										placeholder={'ईमेल भर्नुहोस्'}
										maxLength={50}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.email ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.email.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">फोन</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="number"
										className={`textbox_req`}
										language={language}
										classNameName={`input`}
										elementId={'phone_np'}
										placeholder={'फोन न. भर्नुहोस्'}
										maxLength={10}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.phone_np ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.phone_np.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">Phone No.</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="number"
										className={`textbox_req`}
										language={language}
										classNameName={`input`}
										elementId={'phone_en'}
										placeholder={'Enter phone number'}
										maxLength={10}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.phone_en ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.phone_en.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">ठेगाना</Form.Label>
									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={language}
										classNameName={`input`}
										elementId={'address_np'}
										placeholder={'ठेगाना भर्नुहोस्'}
										maxLength={20}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.address_np ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.address_np.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={4} className="mb-3">
								<Form.Group controlId="formBasicEmail">
									<Form.Label className="mb-0">Address</Form.Label>

									<InputField
										css={InputFieldStyleOverwrite}
										type="text"
										className={`textbox_req`}
										language={language}
										classNameName={`input`}
										elementId={'address_en'}
										placeholder={'Enter address'}
										maxLength={50}
										register={register}
										isRequired={true}
										setValue={setValue}
									/>
									{errors.address_en ? (
										<p
											className="text-danger text-small"
											css={css`
												width: 100%;
												display: inline-block;
											`}
										>
											{errors.address_en.message}
										</p>
									) : null}
								</Form.Group>
							</Col>
							<Col md={12} className="d-flex justify-content-end align-items-center">
								<Button variant="secondary" className="mr-2" onClick={() => history.goBack()} disabled={isLoading}>
									रद्द गर्नुहोस्
								</Button>
								<Button type="submit" variant="success" disabled={isLoading}>
									{isLoading ? (
										<VscLoading className="spin" />
									) : (
										<Fragment>{id ? 'अपडेट' : 'सुरक्षित'} गर्नुहोस्</Fragment>
									)}
								</Button>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</Form>
		</Fragment>
	);
};

export default AddWard;
