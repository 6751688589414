/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useEffect, useState, useCallback } from 'react';
import { Button, Card, Row, Col } from "react-bootstrap";
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { FaEdit, FaPlus } from 'react-icons/fa';
import FeatureModal from './FeatureModal';
import ReactSwitch from 'react-switch';
import { toast } from 'react-toastify';

export default function Features() {
  const [features, setFeatures] = useState([]);

  const [editFeatureData, setEditFeatureData] = useState(null);
  const [showFeatureModal, setShowFeatureModal] = useState(false);

  const handleShowFeatureModal = () => setShowFeatureModal(true);
  const handleHideFeatureModal = () => {
    setEditFeatureData(null);
    setShowFeatureModal(false)
  };

  const fetchFeatures = useCallback(async () => {
    try {
      const response = await fetchWrapper.get('/features/');
      setFeatures(response?.data?.results)
    }
    catch (err) {
      console.log(err)
    }
  }, []);

  useEffect(() => {
    fetchFeatures();
  }, []);

  const handleOnSuccess = () => {
    fetchFeatures();
  }

  const handleEditFeature = (data) => {
    setEditFeatureData(data);
    setShowFeatureModal(true);
  }

  return (
    <Card
      css={css`
      border-radius: 8px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    `}
      className="mt-2"
    >
      <Card.Header className="card-header-custom d-block px-3">
        <h2>System Features</h2>
      </Card.Header>
      <Card.Body className="pt-0 pb-3 px-3 mt-2">
        <div className='d-flex justify-content-end'>
          <Button onClick={handleShowFeatureModal}><FaPlus className='mr-2' /> Add</Button>
        </div>


        <Row className="my-3">
          {
            features.map(feature => <Feature feature={feature} key={feature?.id} onEditClick={handleEditFeature} onSuccess={handleOnSuccess} />)
          }
          <Col>
          </Col>
        </Row>
      </Card.Body>

      <FeatureModal show={showFeatureModal} handleClose={handleHideFeatureModal} onSuccess={handleOnSuccess} data={editFeatureData} />
    </Card>
  )
}

const Feature = ({ feature, onEditClick, onSuccess }) => {
  const [enabled, setEnabled] = useState(feature.enabled);
  const handleChange = async (value) => {
    try {
      const response = await fetchWrapper.patch(`/features/${feature.id}/`, {
        enabled: value
      });
      setEnabled(value);
      onSuccess();
      toast.success('परिवर्तन सफलतापूर्वक लागू गरियो।');
    }
    catch (err) { }
  }
  useEffect(() => {
    if (feature?.enabled) {
      setEnabled(feature.enabled);
    }
  }, [feature])

  const handleEditFeature = () => {
    onEditClick(feature);
  }
  return (
    <Col md="6" className="mb-4">
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <h4>{feature.name}</h4>
          <Button onClick={handleEditFeature}><FaEdit /></Button>
        </div>
        <div className='my-2' css={css`height: 1px;background: #a3a3a3;border:none;`} />
        <div className='d-flex justify-content-between align-items-center'>
          {feature.description}

          <ReactSwitch checked={enabled} onChange={handleChange} />
        </div>
      </div></Col>
  )
}