import React from "react";
import { useHistory } from "react-router-dom";

//
import { IoIosArrowBack } from 'react-icons/io';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

/**
 * 
 */
function BackButton() {
	const history = useHistory();

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			पछाडी जानुहोस
		</Tooltip>
	);

	return (
		<div>
			<OverlayTrigger
				placement="bottom"
				delay={{ show: 250, hide: 400 }}
				overlay={renderTooltip}
			>
				<span className="backButton bg-white p-1 rounded cursor-pointer" onClick={() => history.goBack()}>
					<IoIosArrowBack fontSize={20} />
				</span>
			</OverlayTrigger>
		</div>
	);
}

export default BackButton;
