import { FaChartBar, FaCog, FaFile, FaHeadphones, FaHome } from 'react-icons/fa';

export const upperData = [
	{
		id: 1,
		title: 'ड्यासबोर्ड',
		// title_en: 'Dashboard',
		icon: <FaHome />,
		path: '/',
	},

	{
		id: 4,
		title: 'दर्ता',
		// title_en: 'Darta',
		icon: <FaFile />,
		subNav: [
			{
				id: 1,
				title: 'तथ्यांक',
				// title_en: 'list',
				path: '/darta/1',
			},

			{
				id: 2,
				title: 'नयाँ',
				// title_en: 'Form',
				path: '/new/darta',
			},
		],
	},
	{
		id: 5,
		title: 'चलानी',
		// title_en: 'Chalani',
		icon: <FaFile />,
		subNav: [
			{
				id: 1,
				title: 'तथ्यांक',
				// title_en: 'list',
				path: '/chalani/1',
			},
			{
				id: 2,
				title: 'नयाँ',
				// title_en: 'Form',
				path: '/new/chalani',
			},
		],
	},
];

export const lowerData = [
	{
		id: 6,
		title: 'सेटिंग्स',
		// title_en: 'Settings',
		icon: <FaCog />,
		path: '/settings',
		rule: 'view:settings',
	},
	{
		id: 7,
		title: 'रिपोर्ट',
		// title_en: 'Report',
		icon: <FaChartBar />,
		path: '/reports',
		rule: 'view:reports',
	},

	{
		id: 10,
		title: 'सपोर्ट',
		// title_en: 'Support',
		icon: <FaHeadphones />,
		path: '/support',
		rule: 'view:support',
	},
];
