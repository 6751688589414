/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { upperData, lowerData } from "./SidebarData";
import SidebarButtonRenderer from "./SidebarButtonRenderer";

const Sidebar = () => {
  const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);
  const nagarpalikaInfo = useSelector(
    (state) => state.users?.user?.nagarpalika_info
  );
  const user = useSelector((state) => state.users?.user);
  const isSSO = process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === "true";

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-top">
        <a
          href={
            isSSO ? process.env.REACT_APP_SSO_URL + "/application/user" : "/"
          }
        >
          <img
            src={nagarpalikaInfo?.main_logo || "/assets/images/nep_logo.png"}
            className="logo"
            alt=""
          />
        </a>
        {nagarpalikaInfo ? (
          <div>
            <h5>{nagarpalikaInfo.nagarpalika_name_np}</h5>
            <p
              css={css`
                font-size: 1.1rem;
              `}
            >
              <Fragment>
                {user?.role === "ito_admin" ? (
                  <Fragment>
                    {nagarpalika?.name_en?.includes("Rural") ? "गाउँ " : "नगर "}
                    कार्यपालिकाको कार्यालय
                  </Fragment>
                ) : user?.organization?.name_np ? (
                  <Fragment>
                    {user.organization.name_np}
                    {user.organization?.type === "sakha"
                      ? " शाखा"
                      : " नं. वडा कार्यालय"}
                  </Fragment>
                ) : null}
              </Fragment>
            </p>
            <p
              css={css`
                font-size: 0.9rem;
              `}
            >
              {user?.organization?.address_np}
            </p>
          </div>
        ) : (
          <div>
            <h5>{nagarpalika?.name_np}</h5>
            <p
              css={css`
                font-size: 1.1rem;
              `}
            >
              <Fragment>
                {user?.role === "ito_admin" ? (
                  <Fragment>
                    {nagarpalika?.name_en?.includes("Rural") ? "गाउँ " : "नगर "}
                    कार्यपालिकाको कार्यालय
                  </Fragment>
                ) : user?.organization?.name_np ? (
                  <Fragment>
                    {user.organization.name_np}
                    {user.organization.type === "sakha"
                      ? " शाखा"
                      : " नं. वडा कार्यालय"}
                  </Fragment>
                ) : null}
              </Fragment>
            </p>
            <p
              css={css`
                font-size: 0.9rem;
              `}
            >
              {user?.organization?.address_np}
            </p>
          </div>
        )}
      </div>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <h4
        css={css`
          text-align: center;
          padding: 0.5rem 0;
          color: black;
        `}
      >
        {"दर्ता/चलानी प्रणाली"}
      </h4>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <div className="menus">
        {upperData && <SidebarButtonRenderer data={upperData} user={user} />}
        {lowerData && <SidebarButtonRenderer data={lowerData} user={user} />}
      </div>
    </div>
  );
};

export default Sidebar;
