import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({
  component: Component,
  accessGrantedRoles,
  ...rest
}) => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const user = useSelector((state) => state.users.user);
  const role = user?.role;

  const features = useSelector(state => state?.system_features?.features);
  const { LOGIN_WITH_SSO } = features;

  return (
    <Route
      {...rest}
      render={(props) => {
        const localStorageUser = window.localStorage.getItem("user");
        if (!isLoggedIn || !localStorageUser) {
          if (LOGIN_WITH_SSO === 'true') {
            const redirectLink = document.createElement('a');
            let url = `http://localhost:3000`;
            if (process.env.NODE_ENV === "production") {
              url = `${process.env.REACT_APP_SSO_URL}`;
            }
            let link = `${url}/signout`;

            redirectLink.href = link;
            redirectLink.click();
          }
          return <Redirect to="/login" />;
        }
        if (user) {
          if (accessGrantedRoles.includes(role))
            return <Component {...props} />;
          return <Redirect to="/" />;
        }
      }
      }
    />
  );
};

// const FirstConnectedComponent = ContainerCreater(PrivateRoute);
// const SecondConnectedComponent = ContainerCreater(AuthRoute);
const AuthRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  if (isLoggedIn) localStorage.removeItem('showLoginPage');
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn === true ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const FirstConnectedComponent = PrivateRoute;
const SecondConnectedComponent = AuthRoute;

export {
  FirstConnectedComponent as PrivateRoute,
  SecondConnectedComponent as AuthRoute,
};
