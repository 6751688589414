import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { nepaliNumbers } from '../../_datas';
import { StyledInput, StyledTextArea } from './style/StyledIndex';
import { setConvertedDates } from '../../_redux/slices/formTemplates';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { nepaliToEnglishNumber } from 'nepali-number';
import SelectDistrict from '../../_elements/SelectDistrict';
import SelectPalika from '../../_elements/SelectPalika';
import SelectProvince from '../../_elements/SelectProvince';
import setUnicode from '../../_helpers/language/type';
import { nepaliNumber } from '../../_helpers/methods';
import { handleConvertNepali } from '../../_helpers/language/fonts';
import NepaliDate from 'nepali-date-converter';
import DateConverter from 'dateconverter';
import { Button, Modal, Table } from 'react-bootstrap';

function InputField({
	type,
	language,
	getInputData,
	register,
	maxLength,
	elementId,
	value,
	errors = {},
	setValue,
	setConvertedDates,
	properties = {},
	validation = {},
	autoComplete = 'on',
	placeHolder = '',
	disabled,
	handleKeyPress,
	...rest
}) {
	const [nepali, setNepali] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [specialCharacters, setSpecialCharacters] = useState(false);
	const [specialCharacterList, setSpecialCharacterList] = useState(false);

	const dates = useSelector((state) => state.formTemplates.dates);
	const fontType = useSelector((state) => state.language.fontType);

	useEffect(() => { setNepali(value) }, [value]);

	function moveFocus(e) {
		e.target.parentElement.nextElementSibling?.querySelector('input').focus();
	}

	function switchFocus(e) {
		let val = e.target.value;
		// eslint-disable-next-line no-unused-vars
		const [_, suff] = elementId.split('.');
		switch (suff) {
			case 'year':
				if (val.length === 4) {
					moveFocus(e);
				}
				break;
			case 'month':
				val.length === 2 && moveFocus(e);
				break;
			case 'day':
				val.length === 2 && moveFocus(e);
				break;
			default:
				break;
		}
	}

	function fillDigit(e) {
		let val = e.target.value;
		if (val) {
			// eslint-disable-next-line no-unused-vars
			const [pref, suff] = elementId.split('.');
			switch (suff) {
				case 'month':
					if (val.length >= 2) {
						return null;
					}
					if (language === 'english') {
						val = val.padStart(2, 0);
					} else {
						val = val.padStart(2, '०');
					}
					setValue(elementId, val);
					setNepali(val);
					break;
				case 'day':
					if (val.length >= 2) {
						return null;
					}
					if (language === 'english') {
						val = val.padStart(2, 0);
					} else {
						val = val.padStart(2, '०');
					}
					setValue(elementId, val);
					setNepali(val);
					break;
				default:
					break;
			}
		}
	}

	useEffect(() => {
		const year_ad = dates.find((date) => date.id === 'date_of_birth_AD.year' || date.id === 'date_application_AD.year');
		const month_ad = dates.find(
			(date) => date.id === 'date_of_birth_AD.month' || date.id === 'date_application_AD.month'
		);
		const day_ad = dates.find((date) => date.id === 'date_of_birth_AD.day' || date.id === 'date_application_AD.day');
		const year_bs = dates.find((date) => date.id === 'date_of_birth_BS.year' || date.id === 'date_application_BS.year');
		const month_bs = dates.find(
			(date) => date.id === 'date_of_birth_BS.month' || date.id === 'date_application_BS.month'
		);
		const day_bs = dates.find((date) => date.id === 'date_of_birth_BS.day' || date.id === 'date_application_BS.day');

		if (
			year_ad &&
			year_ad.value.length > 3 &&
			year_ad.value.length < 5 &&
			month_ad &&
			month_ad.value.length > 1 &&
			month_ad.value.length < 3 &&
			day_ad &&
			day_ad.value.length > 1 &&
			day_ad.value.length < 3
		) {
			try {
				var adDate = DateConverter(year_ad.value, month_ad.value, day_ad.value);
				var bsDate = adDate.convertToBS().toBSString();

				let [year, month, date] = bsDate.split('-');

				if (elementId === 'date_of_birth_BS.year' || elementId === 'date_application_BS.year') {
					setNepali(nepaliNumber(year));
					setValue(`${elementId}`, nepaliNumber(year));
				}
				if (elementId === 'date_of_birth_BS.month' || elementId === 'date_application_BS.month') {
					let val = ('०' + nepaliNumber(month)).slice(-2);
					setNepali(val);
					setValue(`${elementId}`, nepaliNumber(('0' + month).slice(-2)));
				}
				if (elementId === 'date_of_birth_BS.day' || elementId === 'date_application_BS.day') {
					let val = ('०' + nepaliNumber(date)).slice(-2);
					setNepali(val);
					setValue(`${elementId}`, nepaliNumber(('0' + date).slice(-2)));
				}
			} catch {
				// console.log("Invalid date");
			}
		}
		if (
			year_bs &&
			year_bs.value.length > 3 &&
			year_bs.value.length < 5 &&
			month_bs &&
			month_bs.value.length > 1 &&
			month_bs.value.length < 3 &&
			day_bs &&
			day_bs.value.length > 1 &&
			day_bs.value.length < 3
		) {
			const [y, m, d] = nepaliToEnglishNumber(
				`${year_bs.value}, 
        ${month_bs.value}, 
        ${day_bs.value}`
			).split(', ');

			try {
				let { year, month, date } = new NepaliDate(parseInt(y), parseInt(m) - 1, parseInt(d)).getAD();

				if (elementId === 'date_of_birth_AD.year' || elementId === 'date_application_AD.year') {
					setNepali(y);
					setValue(`${elementId}`, year);
				}
				if (elementId === 'date_of_birth_AD.month' || elementId === 'date_application_AD.month') {
					setNepali(('0' + (month + 1)).slice(-2));
					setValue(`${elementId}`, ('0' + (month + 1)).slice(-2));
				}
				if (elementId === 'date_of_birth_AD.day' || elementId === 'date_application_AD.day') {
					setNepali(('0' + date).slice(-2));
					setValue(`${elementId}`, ('0' + date).slice(-2));
				}
			} catch {
				// console.log("Invalid date");
			}
		}
	}, [dates, elementId, nepali, setValue]);

	const englishNumber = (number) => {
		let strEngNum = '';
		for (let i = 0; i < number.length; i++) {
			const index = nepaliNumbers.indexOf(number[i]);
			strEngNum += index !== -1 ? index : number[i];
		}
		return String(strEngNum);
	};
	function setDate(e) {
		const [id_prefix, id_suffix] = elementId.split('.');
		let id = `${id_prefix}.${id_suffix}`;
		if (language === 'nepali' && elementId === `${id_prefix}.${id_suffix}`) {
			setConvertedDates({ id: id, value: e.target.value });
		} else if (language === 'english' && elementId === `${id_prefix}.${id_suffix}`) {
			setConvertedDates({
				id: `${id_prefix}.${id_suffix}`,
				value: e.target.value,
			});
		}
	}

	const isNumeric = (number) => {
		// eslint-disable-next-line no-self-compare
		if (+number === +number) {
			// if is a number
			return true;
		}
		return false;
	};

	function checkValue(str, max) {
		if (str.charAt(0) !== '0' || str === '00') {
			var num = parseInt(str);
			if (isNaN(num) || num <= 0 || num > max) num = 1;
			str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
		}
		return str;
	}

	const handleNepaliTypeing = (e) => {
		const text = e.target.value;
		let output = '';

		switch (type) {
			case 'text':
			case 'textarea':
				if (language === 'nepali') {
					if (fontType === 'preeti' || fontType === 'pcs_nepali' || fontType === 'kantipur') {
						output = handleConvertNepali(fontType, text);
						setNepali(output);
						setValue && setValue(elementId, text);

						return getInputData(output);
					} else if (language === 'nepali' && (fontType === 'traditional' || fontType === 'romanized')) {
						if (
							e.nativeEvent.inputType === 'deleteContentBackward' ||
							e.nativeEvent.inputType === 'deleteWordBackward'
						) {
							setNepali(text);
							setValue && setValue(elementId, text);

							return getInputData(text);
						} else if (!e.nativeEvent.inputType) {
							setNepali(text);
							setValue && setValue(elementId, text);
							if (getInputData) {
								return getInputData(text);
							}
						}
						return;
					} else {
						setNepali(text);
						setValue && setValue(elementId, text);

						return getInputData(text);
					}
				} else if (language === 'english') {
					setNepali(text);
					setValue && setValue(elementId, text);
					return getInputData(text);
				}
				break;
			case 'number':
				if (language === 'nepali') {
					output = nepaliNumber(text);
					setNepali(output);
					setValue && setValue(elementId, output);

					e.target.value = output;
					return getInputData(output);
				} else if (language === 'english') {
					for (let i = 0; i < text.length; i++) {
						output += isNumeric(text[i]) ? text[i] : '';
					}

					setNepali(output);
					setValue && setValue(elementId, output);
					return getInputData(output);
				}
				break;

			case 'date':
				if (language === 'english') {
					if (text.length > 14) return null;
					if (/\D\/$/.test(text)) output = text.substr(0, text.length - 3);
					else output = text;
					let values = output.split('/').map(function (v) {
						return v.replace(/\D/g, '');
					});
					if (values[1]) values[1] = checkValue(values[1], 12);
					if (values[2]) values[2] = checkValue(values[2], 32);

					let dateOutput = values.map(function (v, i) {
						return v.length === 4 && i === 0 ? v + ' / ' : v.length === 2 && i > 0 && i <= 2 ? v + ' / ' : v;
					});
					const dateString = dateOutput.join('').substr(0, 14);
					setNepali(dateString);
					setValue && setValue(elementId, dateString);

					return getInputData(dateString);
				} else if (language === 'nepali') {
					if (text.length > 14) return null;
					const numberText = englishNumber(text);

					if (/\D\/$/.test(numberText)) output = numberText.substr(0, numberText.length - 3);
					else output = numberText;
					let values = output.split('/').map(function (v) {
						return v.replace(/\D/g, '');
					});

					if (values[1]) values[1] = checkValue(values[1], 12);

					if (values[2]) values[2] = checkValue(values[2], 32);

					let dateOutput = values.map(function (v, i) {
						return v.length === 4 && i === 0
							? nepaliNumber(v) + ' / '
							: v.length === 2 && i > 0 && i <= 2
								? nepaliNumber(v) + ' / '
								: nepaliNumber(v);
					});
					const dateString = dateOutput.join('').substr(0, 14);
					e.target.value = dateString;
					setNepali(dateString);
					setValue && setValue(elementId, dateString);
					return getInputData(dateString);
				}
				break;
			default:
				break;
		}
	};

	const onKeyPress = (e) => {
		if (language === 'nepali' && (type === 'text' || type === 'textarea')) {
			if (fontType === 'traditional' || fontType === 'romanized') {
				const { value, newEndPos } = setUnicode(e, fontType);
				setNepali(value);
				setValue && setValue(elementId, value);
				getInputData(value);
				e.target.setSelectionRange(newEndPos, newEndPos);
			}
		}
		if (e.key === 'Enter' && handleKeyPress) {
			handleKeyPress(e);
			if (setValue) {
				setValue(elementId, '');
			}
			setNepali('');
		}
	};

	const onChange = (e) => {
		if (properties.isBlock) {
			e.target.value = e.target.value.toUpperCase();
		}
		handleNepaliTypeing(e);
		if (elementId.startsWith('date_of_birth') || elementId.startsWith('date_application')) {
			setDate(e);
		}
		if (!setValue) register(elementId).onChange(e);
	};

	const onKeyUp = (e) => {
		if (elementId.startsWith('date')) {
			switchFocus(e);
		}
	};

	const onBlur = (e) => {
		if (elementId.startsWith('date')) {
			fillDigit(e);
		}
		if (language === 'nepali') {
			setTimeout(() => setSpecialCharacters(false), 500);
		}
	};

	const onKeyDown = (e) => {
		if (e.ctrlKey && e.key === '>') {
			const newValue = nepali + '.';
			setNepali(newValue);
			setValue && setValue(elementId, newValue);
			getInputData(newValue);
		} else if (e.ctrlKey && e.key === '?') {
			const newValue = nepali + '/';
			setNepali(newValue);
			setValue && setValue(elementId, newValue);
			getInputData(newValue);
		}
	};

	const applySpecialCharacters = (char) => {
		const newValue = nepali + char;
		setNepali(newValue);
		setValue && setValue(elementId, newValue);
		getInputData(newValue);
		setSpecialCharacterList(false);
		document.getElementById(elementId).focus();
	};

	const handleOnPaste = (event) => {
		event.preventDefault();
		let pasteValue = (event.clipboardData || window.Clipboard).getData('text');
		const newValue = `${nepali || ''}${pasteValue}`;
		setNepali(newValue);
		setValue && setValue(elementId, newValue);
		if (getInputData) {
			getInputData(newValue);
		}
	};

	if (register) {
		if (elementId === 'select_province_np') {
			return <SelectProvince elementID={elementId} setValue={setValue} />;
		} else if (elementId === 'select_district_np') {
			return <SelectDistrict setValue={setValue} elementID={elementId} />;
		} else if (elementId === 'select_palika_np') {
			return <SelectPalika setValue={setValue} elementID={elementId} />;
		} else {
			if (type === 'textarea') {
				return (
					<Fragment>
						<StyledTextArea
							rows={properties.rows ? properties.rows : '5'}
							id={elementId}
							value={nepali}
							autoComplete={autoComplete}
							{...rest}
							{...register(elementId.split("'").join('&&&&'), validation)}
							onChange={onChange}
							placeholder={placeHolder}
							onKeyPress={onKeyPress}
							onKeyUp={onKeyUp}
							onBlur={onBlur}
							disabled={disabled}
							onKeyDown={onKeyDown}
							onPaste={handleOnPaste}
						/>
					</Fragment>
				);
			}

			return (
				<span style={{ position: 'relative' }}>
					<StyledInput
						id={elementId}
						value={nepali}
						autoComplete={autoComplete}
						{...rest}
						{...register(elementId.split("'").join('&&&&'), validation)}
						onChange={onChange}
						placeholder={placeHolder}
						onKeyPress={onKeyPress}
						onKeyUp={onKeyUp}
						onKeyDown={onKeyDown}
						onBlur={onBlur}
						disabled={disabled}
						onFocus={(e) => {
							if (language === 'nepali') {
								setSpecialCharacters(true);
							}
						}}
						onPaste={handleOnPaste}
					/>

					{specialCharacterList && (
						<Modal
							show={specialCharacterList}
							aria-labelledby="contained-modal-title-vcenter"
							centered
							onHide={() => setSpecialCharacterList(false)}
						>
							<Modal.Body>
								<h4 className="mb-4">Special Characters</h4>
								<Table>
									<thead>
										<tr>
											<th style={{ width: '100px' }}>Characters</th>
											<th>Shortcut</th>
											<th style={{ width: '100px' }}>Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>.</td>
											<td>
												<kbd className="keyboard-shortcut">Ctrl + Shift + {'.'}</kbd>
											</td>
											<td>
												<Button onClick={(e) => applySpecialCharacters('.')}>Apply</Button>
											</td>
										</tr>
										<tr>
											<td>/</td>
											<td>
												<kbd className="keyboard-shortcut">Ctrl + Shift + {'?'}</kbd>
											</td>
											<td>
												<Button onClick={(e) => applySpecialCharacters('/')}>Apply</Button>
											</td>
										</tr>
									</tbody>
								</Table>
							</Modal.Body>
						</Modal>
					)}
				</span>
			);
		}
	} else {
		return type === 'textarea' ? (
			<StyledTextArea
				rows={properties.rows ? properties.rows : '5'}
				id={elementId}
				onChange={handleNepaliTypeing}
				onKeyPress={onKeyPress}
				placeholder={placeHolder}
				value={nepali}
				autoComplete={autoComplete}
				{...rest}
				disabled={disabled}
				onPaste={handleOnPaste}
			/>
		) : (
			<StyledInput
				id={elementId}
				onChange={handleNepaliTypeing}
				onKeyPress={onKeyPress}
				placeholder={placeHolder}
				value={nepali}
				autoComplete={autoComplete}
				{...rest}
				disabled={disabled}
				onPaste={handleOnPaste}
			/>
		);
	}
}

InputField.defaultProps = {
	getInputData: () => { },
	language: 'nepali',
	type: 'text',
	value: '',
};

InputField.propTypes = {
	type: PropTypes.string,
	language: PropTypes.string,
	style: PropTypes.any,
	className: PropTypes.string,
	onChange: PropTypes.func,
	onkeydown: PropTypes.func,
	handleKeyPress: PropTypes.func,
};

export default connect(null, { setConvertedDates })(InputField);
