import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";

const initialState = {
  isLoading: false,
  dartaList: [],
  darta: null,
  count: 0,
  organziation: null,
};

const dartaSlice = createSlice({
  name: "darta",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDartaList: (state, action) => {
      state.dartaList = action.payload.results;
      state.count = action.payload.count;
    },
    setOrganization: (state, action) => {
      state.organziation = action.payload;
    },
  },
});

const { setDartaList, setIsLoading, setOrganization } = dartaSlice.actions;

export default dartaSlice.reducer;

export const getDartaList =
  (page, organization, filters = {}) =>
  async (dispatch) => {
    try {
      const queryParams = new URLSearchParams();
      const { dartaNo, sender, fiscalYear } = filters;

      if (!fiscalYear) {
        return;
      }
      queryParams.append("fiscal_year", fiscalYear);
      if (page) queryParams.append("page", page);
      if (organization?.value)
        queryParams.append("organization", organization.value);
      if (dartaNo) queryParams.append("serial_number", dartaNo);
      if (sender) queryParams.append("sender", sender);

      dispatch(setIsLoading(true));
      const response = await fetchWrapper.get(`/darta/?${queryParams}`);
      dispatch(setDartaList(response));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setIsLoading(false));
    }
  };

export const handleSetOrganization = (organization) => async (dispatch) => {
  dispatch(setOrganization(organization));
};
