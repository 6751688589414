import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiMessage } from './ui';

export const initialState = {
	loading: false,
	hasErrors: false,
	ito_users: [],
	count: 0,
};

// A slice
const ito_usersSlice = createSlice({
	name: 'ito_users',
	initialState,
	reducers: {
		getItoUsers: (state, action) => {
			state.ito_users = action.payload.data.results;
			state.count = action.payload.count;
		},

		addItoUser: (state, action) => {
			state.ito_users = [action.payload, ...state.ito_users];
			state.count = state.count + 1;
		},

		updateItoUser: (state, action) => {
			const ito_users = [...state.ito_users];

			const editedIndex = ito_users.findIndex((ito_user) => ito_user.id === action.payload.id);

			if (editedIndex !== -1) {
				ito_users[editedIndex] = action.payload;
			}
			state.ito_users = ito_users;
		},
		removeItoUser: (state, action) => {
			const ito_users = [...state.ito_users];

			const editedIndex = ito_users.findIndex((ito_user) => ito_user.id === action.payload);
			ito_users.splice(editedIndex, 1);
			state.ito_users = ito_users;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
	},
});

// Actions generated from the slice
const { getItoUsers, addItoUser, updateItoUser, removeItoUser, setLoading } = ito_usersSlice.actions;

// export user selector to get the slice in any component
export const ito_usersSelector = (state) => state.ito_users;

// export The reducer
const ito_usersReducer = ito_usersSlice.reducer;

export default ito_usersReducer;

// Actions
export const fetchItoUsers = () => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const ito_users = await fetchWrapper.get('/profile/');
		dispatch(getItoUsers(ito_users));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const createItoUser = (data, setShowAdd) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const ito_user = await fetchWrapper.post('/profile/', data);
		dispatch(addItoUser(ito_user.data));
		setShowAdd((prev) => !prev);
		// console.log('ito_user.message', ito_user.message)
		dispatch(setUiMessage(ito_user.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const editItoUser = (data, setShowAdd, setDocumentDetail) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const ito_user = await fetchWrapper.patch(`/profile/${data.id}/`, data);

		dispatch(updateItoUser(ito_user.data));
		setShowAdd((prev) => !prev);
		setDocumentDetail(null);
		dispatch(setUiMessage(ito_user.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

export const deleteItoUser = (id) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const ito_user = await fetchWrapper.delete(`/profile/${id}/`);
		dispatch(removeItoUser(id));
		dispatch(setUiMessage(ito_user.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};
