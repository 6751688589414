import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { urlPublicChecker } from '../../_helpers/methods';

export const initialState = {
	templates: [],
	count: null,
	message: null,
	errors: null,
	loading: false,
	dates: [],
	select: [],
};

// A slice
const formTemplatesSlice = createSlice({
	name: 'formTemplates',
	initialState,
	reducers: {
		setTemplates: (state, action) => {
			state.count = action.payload.count;
			if (action.payload.type === 'array') {
				state.templates = action.payload.data;
			} else {
				state.templates = [...state.templates, action.payload.data];
			}
		},
		setDates: (state, action) => {
			const val = state.dates.find((el) => el.id === action.payload.id);
			if (val) {
				val.value = action.payload.value;
				state.dates = [...state.dates, val];
				state.dates = state.dates.filter((el) => el.id !== val.id);
			}
			state.dates = [...state.dates, action.payload];
		},
		loadingUi: (state, action) => {
			state.loading = true;
		},
		stopLoadingUi: (state, action) => {
			state.loading = false;
		},
		setMessage: (state, action) => {
			state.message = action.payload;
		},
		clearMessage: (state, action) => {
			state.message = null;
		},
		setError: (state, action) => {
			try {
				state.message = {
					error: true,
					message: action?.payload[0],
				};
			} catch (err) {
				state.message = {
					error: true,
				};
			}
		},
		setSelect: (state, action) => {
			const val = state.select.find((el) => el.key === action.payload.key);
			if (val) {
				val.value = action.payload.value;
				state.select = [...state.select, val];
				state.select = state.select.filter((el) => el.key !== val.key);
			}
			state.select = [...state.select, action.payload];
		},
	},
});

const { setTemplates, loadingUi, stopLoadingUi, clearMessage, setError, setMessage, setDates, setSelect } =
	formTemplatesSlice.actions;

export const formTemplatesSelector = (state) => state.formTemplates;

const formTemplatesReducer = formTemplatesSlice.reducer;

export default formTemplatesReducer;

// Actions

export const startUiLoading = () => async (dispatch) => {
	dispatch(loadingUi());
};

export const stopUiLoading = () => async (dispatch) => {
	dispatch(stopLoadingUi());
};

export const setFormTemplates = (isPublic) => async (dispatch) => {
	try {
		dispatch(startUiLoading());
		let url = '/template/';
		if (isPublic) {
			url = '/public' + url;
		}
		let response = await fetchWrapper.get(url);
		dispatch(
			setTemplates({
				data: response.data.results,
				count: response.data.count,
				type: 'array',
			})
		);
		dispatch(stopUiLoading());
	} catch (err) {
		// console.log(err);
		dispatch(stopUiLoading());
	}
};

export const setTemplateList = (page, search) => async (dispatch) => {
	try {
		dispatch(startUiLoading());
		const response = await fetchWrapper.get(`/template/?page=${page}${search ? `&title=${search}` : ''}`);
		dispatch(
			setTemplates({
				data: response.data.results,
				count: response.data.count,
				type: 'array',
			})
		);
		dispatch(stopUiLoading());
	} catch (err) {
		dispatch(stopUiLoading());
	}
};

export const resetMessage = (data) => async (dispatch) => {
	dispatch(setMessage(data));
};
export const setFormTemplate = (id, isPublic) => async (dispatch) => {
	try {
		dispatch(startUiLoading());
		let url = urlPublicChecker(`/template/${id}/`, isPublic);
		const response = await fetchWrapper.get(url);
		dispatch(setTemplates({ data: response.data, type: 'object' }));
		dispatch(stopUiLoading());
		return response.data;
	} catch (err) {
		dispatch(stopUiLoading());
	}
};

export const clearUiMessage = () => async (dispatch) => {
	dispatch(clearMessage());
};
export const setUiError = (errorMessage) => async (dispatch) => {
	dispatch(setError(errorMessage));
};

export const setConvertedDates = (data) => async (dispatch) => {
	dispatch(setDates(data));
};

export const setSelectValue = (data) => async (dispatch) => {
	dispatch(setSelect(data));
};
